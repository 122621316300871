import {
  OrganizationResponse,
  BuildingResponse,
  WebAssetResponse,
} from "@akitabox/api-client";

export const handleLegacyAddServiceRequest = async (
  injector: any,
  organization: OrganizationResponse,
  building: BuildingResponse,
  assets: WebAssetResponse[]
) => {
  const transformedAssets = assets.map((asset: WebAssetResponse) => {
    const { building, ...rest } = asset;
    return {
      ...rest,
      building: building?._id,
    };
  });
  const asset = transformedAssets[0];
  await injector.get("CreateRequestDialog").show({
    locals: {
      organization,
      building,
      asset,
    },
  });
};

export const handleLegacyAddWorkOrder = async (
  injector: any,
  organization: OrganizationResponse,
  building: BuildingResponse,
  assets: WebAssetResponse[]
) => {
  await injector.get("CreateWorkOrderDialog").show({
    locals: {
      organization,
      building,
      assets: assets,
    },
  });
};

export const handleLegacyAddMaintenanceSchedule = async (
  injector: any,
  organization: OrganizationResponse,
  building: BuildingResponse,
  assets: WebAssetResponse[]
) => {
  const transformedAssets = assets.map((asset: WebAssetResponse) => {
    const { building, ...rest } = asset;
    return {
      ...rest,
      building: building?._id,
    };
  });
  await injector.get("CreateScheduleDialog").show({
    locals: {
      organization,
      building,
      assets: transformedAssets,
    },
  });
};

export const handleLegacyDecommission = async (
  injector: any,
  assets: WebAssetResponse[]
) => {
  const transformedAssets = assets.map((asset: WebAssetResponse) => {
    const { building, ...rest } = asset;
    return {
      ...rest,
      building: building?._id,
    };
  });
  const asset = transformedAssets[0];
  await injector.get("DecommissionDialog").show({
    locals: {
      asset,
    },
  });
};

export const handleLegacyDelete = async (
  injector: any,
  assets: WebAssetResponse[]
) => {
  assets = assets.map((asset: any) => {
    const { building, ...rest } = asset;
    return {
      ...rest,
      building: building?._id,
    };
  });
  const asset = assets[0];
  await injector.get("DeleteDialog").show({
    locals: {
      asset,
    },
  });
};

export const handleLegacyBulkDelete = async (
  injector: any,
  assets: WebAssetResponse[]
) => {
  assets = assets.map((asset: any) => {
    const { building, ...rest } = asset;
    return {
      ...rest,
      buildingId: building?._id,
    };
  });
  await injector.get("BulkDeleteAssetDialog").show({
    locals: {
      assets,
    },
  });
};
