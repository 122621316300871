import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { FC, useCallback, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { stylesheet } from "../stylesheet";
import {
  ReportBlockBuildingFilters,
  ReportBlockSelectionDataGrid,
  ReportBlockSelectionDataGridFilters,
} from "./ReportBlockSelectionDataGrid";
import {
  AttachmentResponse,
  ReportBlockResponse,
  WebAssetResponse,
  WebReportBlockBuildingResponse,
} from "@akitabox/api-client";
import { api } from "../api";
import SaveIcon from "@mui/icons-material/Save";
import { useGlobalSnackbar } from "../consecutive-snackbar/GlobalSnackbar";
import { GridSortModel } from "@mui/x-data-grid-pro";

export const ASSET_SELECTION_TEMPLATE_OPTIONS = [
  "image-grid-with-details",
  "image-grid",
  "single-image-with-details",
  "single-image",
] as const;

export type ReportBlockSelectionTemplate =
  typeof ASSET_SELECTION_TEMPLATE_OPTIONS[number];

export type AssetAttachmentSelection = {
  [key: string]: {
    default?: AttachmentResponse;
    attachments: AttachmentResponse[];
    loading: boolean;
  };
};

export type ReportBlockSelectionDialogResult =
  | {
      action: "cancel";
    }
  | {
      action: "confirm";
      block: ReportBlockResponse;
      blockContents: string[] | null;
    };

export interface ReportBlockSelectionDialogProps
  extends Omit<DialogProps, "onClose"> {
  organizationId: string;
  onClose: (result: ReportBlockSelectionDialogResult) => Promise<void>;
}

export const ReportBlockSelectionDialog: FC<
  ReportBlockSelectionDialogProps
> = ({ organizationId, onClose, ...props }) => {
  const { simple } = useGlobalSnackbar();

  const [loading, setLoading] = useState(false);
  const [selectedAssets, setSelectedAssets] = useState<WebAssetResponse[]>([]);
  const [selectedBuildings, setSelectedBuildings] = useState<
    WebReportBlockBuildingResponse[]
  >([]);
  const [assetSortModel, setAssetSortModel] = useState<GridSortModel>([
    { field: "name", sort: "asc" },
  ]);
  const [buildingSortModel, setBuildingSortModel] = useState<GridSortModel>([
    { field: "name", sort: "asc" },
  ]);
  const [insertAllFilters, setInsertAllFilters] = useState<
    ReportBlockSelectionDataGridFilters | ReportBlockBuildingFilters | undefined
  >();

  // Both these are used to display the count of assets and buildings when user interacts with "Insert All" feature
  const [assetsCount, setAssetsCount] = useState<number>(0);
  const [buildingsCount, setBuildingsCount] = useState<number>(0);

  const [selectedReportBlock, setSelectedReportBlock] =
    useState<ReportBlockResponse | null>(null);

  const cancelDialog = useCallback(() => {
    resetState();
    onClose({ action: "cancel" });
  }, [onClose]);

  const handleClose = useCallback<
    Exclude<DialogProps["onClose"], null | undefined>
  >(
    (e, reason) => {
      if (reason === "backdropClick" || loading) {
        return;
      }
      cancelDialog();
    },
    [cancelDialog, loading]
  );

  const resetState = () => {
    setLoading(false);
    setSelectedAssets([]);
    setAssetSortModel([{ field: "name", sort: "asc" }]);
    setSelectedBuildings([]);
    setBuildingSortModel([{ field: "name", sort: "asc" }]);
    setSelectedReportBlock(null);
    setAssetsCount(0);
    setBuildingsCount(0);
    setInsertAllFilters(undefined);
  };

  const fetchAllAssets = async () => {
    const limit = 100;
    let skip = 0;

    // sort in reverse order because blocks will be inserted in reverse
    const sortOrder = assetSortModel.find((item) => item.field === "name");
    const sort = sortOrder
      ? `${sortOrder.field},${sortOrder.sort === "asc" ? "desc" : "asc"}`
      : "name,desc";
    let assets = await api.web
      .getAssets({
        orgId: organizationId,
        ...(insertAllFilters ? insertAllFilters : {}),
        sort,
        limit,
      })
      .then((response) => response.data);

    while (assets.length % limit === 0) {
      const fetchedAssets = await api.web
        .getAssets({
          orgId: organizationId,
          ...(insertAllFilters ? insertAllFilters : {}),
          sort,
          limit,
          skip,
        })
        .then((response) => response.data);

      if (fetchedAssets.length === 0) {
        break;
      }

      assets = assets.concat(fetchedAssets);
      skip += limit;
    }

    return assets;
  };

  const fetchAllbuildings = async () => {
    const limit = 100;
    let skip = 0;

    // sort in reverse order because blocks will be inserted in reverse
    const sortOrder = buildingSortModel.find((item) => item.field === "name");
    const sort = sortOrder
      ? `${sortOrder.field},${sortOrder.sort === "asc" ? "desc" : "asc"}`
      : "name,desc";
    let buildings = await api.web
      .getReportBlockBuildings({
        organizationId,
        ...(insertAllFilters ? insertAllFilters : {}),
        sort,
        limit,
      })
      .then((response) => response.data);

    while (buildings.length % limit === 0) {
      const fetchedBuildings = await api.web
        .getReportBlockBuildings({
          organizationId,
          ...(insertAllFilters ? insertAllFilters : {}),
          limit,
          sort,
          skip,
        })
        .then((response) => response.data);

      if (fetchedBuildings.length === 0) {
        break;
      }

      buildings = buildings.concat(fetchedBuildings);
      skip += limit;
    }

    return buildings;
  };

  return (
    <Dialog fullWidth maxWidth="lg" {...props} onClose={handleClose}>
      <DialogTitle component={Box} css={ss.dialogTitle}>
        <Typography variant="h6" color="inherit">
          Insert Blocks
        </Typography>
        <IconButton color="inherit" onClick={cancelDialog}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <ReportBlockSelectionDataGrid
          organizationId={organizationId}
          selectedReportBlock={selectedReportBlock}
          setAssetSortModel={setAssetSortModel}
          assetSortModel={assetSortModel}
          setBuildingSortModel={setBuildingSortModel}
          buildingSortModel={buildingSortModel}
          setSelectedAssets={setSelectedAssets}
          setSelectedReportBlock={setSelectedReportBlock}
          setSelectedBuildings={setSelectedBuildings}
          onFilterChange={(filter) => {
            setInsertAllFilters(filter);
          }}
          onAssetCountChange={(count) => setAssetsCount(count)}
          onBuildingsCountChange={(count) => setBuildingsCount(count)}
        />
      </DialogContent>
      <DialogActions
        css={{
          justifyContent: selectedReportBlock ? "space-between" : "flex-end",
        }}
      >
        {selectedReportBlock !== null && (
          <Button
            onClick={async () => {
              setLoading(true);
              let assets: WebAssetResponse[] = [];
              let buildings: WebReportBlockBuildingResponse[] = [];

              if (selectedReportBlock.context === "asset") {
                assets = await fetchAllAssets();
              } else if (selectedReportBlock.context === "building") {
                buildings = await fetchAllbuildings();
              }

              if (!selectedReportBlock) {
                throw new Error("Report block not selected");
              }

              let assetContents: string[] = [];
              let buildingContents: string[] = [];

              if (assets.length > 0) {
                const assetContentPromises = assets.map(async (asset) => {
                  const content =
                    await api.reportBlockContent.previewReportBlockContent({
                      organizationId,
                      reportBlockId: selectedReportBlock._id,
                      id: asset._id,
                    });
                  return content.data.content || "";
                });
                assetContents = await Promise.all(assetContentPromises);
              } else if (buildings.length > 0) {
                const buildingContentPromises = buildings.map(
                  async (building) => {
                    const content =
                      await api.reportBlockContent.previewReportBlockContent({
                        organizationId,
                        reportBlockId: selectedReportBlock._id,
                        id: building._id,
                      });
                    return content.data.content || "";
                  }
                );
                buildingContents = await Promise.all(buildingContentPromises);
              }

              resetState();
              await onClose({
                action: "confirm",
                block: selectedReportBlock,
                blockContents: assetContents || buildingContents,
              });
            }}
            disabled={loading}
            css={{ flex: "0 0 auto" }}
            variant="contained"
          >
            Insert All{" "}
            {selectedReportBlock.context === "asset"
              ? assetsCount
              : buildingsCount}{" "}
            {selectedReportBlock.context === "asset" ? "Assets" : "Buildings"}
          </Button>
        )}
        <Box>
          <Button onClick={cancelDialog} disabled={loading}>
            CANCEL
          </Button>
          <LoadingButton
            loading={loading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="outlined"
            color="primary"
            disabled={
              (selectedBuildings.length === 0 && selectedAssets.length === 0) ||
              loading
            }
            onClick={async () => {
              setLoading(true);
              try {
                if (!selectedReportBlock) {
                  throw new Error("Report block not selected");
                }

                // Make individual API calls to generate the report block content
                // Data are sorted client side in the data grid, so the API calls will be made in the order of the sort
                const assetContentPromises = Promise.all(
                  selectedAssets.map(async (asset) => {
                    const content =
                      await api.reportBlockContent.previewReportBlockContent({
                        organizationId,
                        reportBlockId: selectedReportBlock._id,
                        id: asset._id,
                      });
                    return content.data.content || "";
                  })
                );

                const buildingContentPromises = Promise.all(
                  selectedBuildings.map(async (building) => {
                    const content =
                      await api.reportBlockContent.previewReportBlockContent({
                        organizationId,
                        reportBlockId: selectedReportBlock._id,
                        id: building._id,
                      });
                    return content.data.content || "";
                  })
                );

                const assetContents = await assetContentPromises.catch(
                  (err) => {
                    let message = err.message;
                    if (err.response?.data?.error?.message) {
                      message = err.response?.data?.error?.message;
                    }
                    simple(message, { severity: "error" });
                    return [];
                  }
                );
                const buildingContents = await buildingContentPromises.catch(
                  (err) => {
                    let message = err.message;
                    if (err.response?.data?.error?.message) {
                      message = err.response?.data?.error?.message;
                    }
                    simple(message, { severity: "error" });
                    return [];
                  }
                );

                await onClose({
                  action: "confirm",
                  block: selectedReportBlock,
                  blockContents: [...assetContents, ...buildingContents],
                });
              } finally {
                setLoading(false);
                resetState();
              }
            }}
          >
            {loading ? "Inserting..." : "Insert"}
          </LoadingButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

const ss = stylesheet({
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
});
