import { FunctionComponent, useEffect, useState } from "react";
import {
  InsightsQueryData,
  QueryWidgetSelection,
} from "./InsightsWidgetSelectionDialog";
import { ExecuteQueryByWidgetId } from "@sisense/sdk-ui";
import { GetWidgetModel } from "./GetWidgetModel";
import { Filter } from "@sisense/sdk-data";

export interface ExecuteMultiQueryByWidgetIdsProps {
  queryWidgetSelections: QueryWidgetSelection[];
  dashboardOid: string;
  selectedDashboard: string;
  externalFilters: Filter[];
  confirmSelections: (queryData?: InsightsQueryData[]) => void;
}

export const ExecuteMultiQueryByWidgetIds: FunctionComponent<
  ExecuteMultiQueryByWidgetIdsProps
> = ({
  queryWidgetSelections,
  dashboardOid,
  externalFilters,
  confirmSelections,
}) => {
  const [queryData, setQueryData] = useState<InsightsQueryData[]>([]);
  const [widgetModels, setWidgetModels] = useState<Map<string, any>>(new Map());
  const [widgetExecutedStates, setWidgetExecutedStates] = useState(
    new Map(
      queryWidgetSelections.map(
        (queryWidgetSelection: QueryWidgetSelection) => [
          queryWidgetSelection.widgetOid,
          false,
        ]
      )
    )
  );

  useEffect(() => {
    if (!queryWidgetSelections.length) {
      confirmSelections();
    } else if (queryData.length === queryWidgetSelections.length) {
      confirmSelections(queryData);
    }
  }, [confirmSelections, queryData, queryWidgetSelections.length]);

  const handleWidgetModel = (widgetOid: string, widgetModel: any) => {
    setWidgetModels(new Map(widgetModels.set(widgetOid, widgetModel)));
  };

  return (
    <div>
      {queryWidgetSelections.map((w, index) => {
        if (!widgetModels.get(w.widgetOid)) {
          return (
            <GetWidgetModel
              key={index}
              dashboardOid={dashboardOid}
              widgetOid={w.widgetOid}
              exitComponent={function (widgetModel) {
                if (widgetModel) {
                  handleWidgetModel(w.widgetOid, widgetModel);
                }
              }}
            />
          );
        }
      })}
      {widgetModels.size === queryWidgetSelections.length &&
        queryWidgetSelections.map((w, index) => {
          if (!widgetExecutedStates.get(w.widgetOid)) {
            return (
              <ExecuteQueryByWidgetId
                key={index}
                widgetOid={w.widgetOid}
                dashboardOid={dashboardOid}
                includeDashboardFilters={true}
                filtersMergeStrategy={
                  externalFilters.length ? "codeFirst" : undefined
                }
                filters={externalFilters.length ? externalFilters : undefined}
              >
                {({ data }) => {
                  if (data) {
                    const widgetModel = widgetModels.get(w.widgetOid);
                    let color = null;
                    let symbol = null;
                    if (!widgetModel) return null;
                    if (widgetModel.chartType === "indicator") {
                      const numberFormatConfig =
                        widgetModel?.dataOptions?.value[0]?.numberFormatConfig;
                      color = widgetModel?.dataOptions?.value[0]?.color?.color;
                      if (numberFormatConfig) {
                        symbol = numberFormatConfig.symbol
                          ? numberFormatConfig.symbol
                          : numberFormatConfig.name === "Percent"
                          ? "%"
                          : null;
                      }
                    }

                    const insightsQueryData: InsightsQueryData = {
                      widgetType: w.widgetType,
                      widgetTitle: w.widgetTitle ? w.widgetTitle : "",
                      symbol: symbol,
                      color: color,
                      widgetId: w.widgetOid,
                      columns: data.columns,
                      rows: data.rows,
                    };
                    setQueryData([...queryData, insightsQueryData]);
                    setWidgetExecutedStates(
                      widgetExecutedStates.set(w.widgetOid, true)
                    );
                    return null;
                  }
                  return null;
                }}
              </ExecuteQueryByWidgetId>
            );
          }

          return null;
        })}
    </div>
  );
};
