import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
// import { useRouteLoaderData } from "react-router";
import { makeUseServiceCall } from "../hooks/useServiceCall";
import { ExportResponse, OrganizationResponse } from "@akitabox/api-client";

import { useApiMutation } from "../hooks/useApiMutation";
import { api } from "../api";

import {
  Card,
  CardContent,
  Checkbox,
  Typography,
  CircularProgress,
  Drawer,
  Grid,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItem,
  ListItemText,
  List,
  Button,
  Popover,
  styled,
  CardHeader,
  Avatar,
  Box,
  TextField,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  OutlinedInput,
  InputLabel,
} from "@mui/material";

import {
  Cancel,
  Checklist,
  ErrorOutline,
  FileUploadOutlined,
  Info,
} from "@mui/icons-material";

const ListItemHover = styled(ListItem)`
  &:hover {
    background-color: #f0f0f0; /* Change this to your desired hover color */
    cursor: pointer;
  }
`;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface ExportColumnsType {
  inspection_program: string;
  "inspection_program.name": string;
  "task.number": string;
  checklist_template: string;
  compliance_code: string;
  "inspection.start_date": string;
  "inspection.due_date": string;
  status: string;
  completed_date: string;
  completed_user: string;
  building: string;
  location_type: string;
  "pin_type.name": string;
  level: string;
  "room.number": string;
  "asset.name": string;
  skip_reason: string;
  reactive_work_orders: string;
  reactive_work_order_links: string;
  external_reactive_work_order_links: string;
}

const ExportColumns = {
  inspection_program: "",
  "inspection_program.name": "Inspection Program Name",
  "task.number": "Work Order Number",
  checklist_template: "Checklist Template Name",
  compliance_code: "Compliance Code",
  "inspection.start_date": "Inspection Start Date",
  "inspection.due_date": "Inspection Due Date",
  status: "Checklist Status",
  completed_date: "Completed Date",
  completed_user: "Completed By",
  building: "Building Name",
  location_type: "Location Type",
  "pin_type.name": "Category Name",
  level: "Floor Name",
  "room.number": "Room Number",
  "asset.name": "Asset Name",
  skip_reason: "Skipped Reason(s)",
  reactive_work_orders: "Reactive Work Order(s)",
  reactive_work_order_links: "Reactive Work Order Link(s)",
  external_reactive_work_order_links: "External Reactive Work Order Link(s)",
};

const CHECKLIST_STATUS_OPTIONS = [
  { value: "FAIL", label: "Failed" },
  { value: "FAIL_LINKED_EXTERNAL_WO", label: "Failed - Linked External WO" },
  { value: "FAIL_LINKED_CLOSED_WO", label: "Failed - Linked Closed WO" },
  { value: "FAIL_LINKED_OPEN_WO", label: "Failed - Linked Open WO" },
  { value: "IN_PROGRESS", label: "Incomplete" },
  { value: "PASS", label: "Passed" },
  { value: "SKIP", label: "Skipped" },
];

type ExportDrawerProps = {
  open: boolean;
  onClose: () => void;
  filters: any;
  organization: OrganizationResponse;
  exportRows: any[];
};

const ExportDrawer: FunctionComponent<ExportDrawerProps> = ({
  open,
  onClose,
  filters,
  organization,
}: ExportDrawerProps) => {
  // const { flags } = useRouteLoaderData("shell") as {
  //   flags: { [key: string]: boolean };
  // };
  const { data: checklistTemplatesResponse } = makeUseServiceCall(
    api.checklistTemplates.get
  )({
    organizationId: organization._id,
    sortBy: "name,asc",
  });
  const checklistTemplates = useMemo(() => {
    return checklistTemplatesResponse?.data || [];
  }, [checklistTemplatesResponse]);
  const { data: buildingResponse } = makeUseServiceCall(
    api.buildings.getByOrganization
  )({
    organizationId: organization._id,
    skip: 0,
    limit: 1000,
  });
  const buildings = useMemo(() => {
    return buildingResponse ? buildingResponse.data : [];
  }, [buildingResponse]);

  const { data: inspectionProgramQueryResponse } = makeUseServiceCall(
    api.inspectionPrograms.get
  )({
    organizationId: organization._id,
    number: filters?.number,
    name: filters?.name,
    priority: filters?.priority,
    status: filters?.status,
    checklistTemplate: filters?.checklist_template,
    assignees: filters?.assignees,
    building: filters?.building,
    limit: 1000,
  });
  const inspectionPrograms = useMemo(() => {
    if (!inspectionProgramQueryResponse) {
      return [];
    }
    return inspectionProgramQueryResponse.data || [];
  }, [inspectionProgramQueryResponse]);

  const [exportId, setExportId] = useState<string>("");

  const { trigger: createExportJob } = useApiMutation(api.myExports.create);
  const { data: exportJobResponse, isLoading: isProcessing } =
    // Note this uses a 3 second refreshInterval and it will be active when there is a defined exportId
    makeUseServiceCall(
      api.myExports.get,
      !!exportId,
      3000
    )({
      exportId,
    });

  // Function to open the dialog to prompt user for start/end dates
  const [exportInfoDialogOpen, setExportInfoDialogOpen] = useState(false);
  const handleExportInfoDialogOpen = () => {
    setExportInfoDialogOpen(true);
  };
  const handleExportInfoDialogClose = () => {
    setExportInfoDialogOpen(false);
  };
  const handleExportInfoReset = () => {
    setSelectedDateRange("custom");
    setExportStartDate(null);
    setExportEndDate(null);
    setSelectedChecklistTemplates([]);
  };
  const [exportStartDate, setExportStartDate] = useState<Date | null>(null);
  const [exportEndDate, setExportEndDate] = useState<Date | null>(null);
  const handleExportStartDateChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!event.target.value) {
      setExportStartDate(null);
      return;
    }
    setExportStartDate(new Date(event.target.value));
  };
  const handleExportEndDateChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!event.target.value) {
      setExportEndDate(null);
      return;
    }
    setExportEndDate(new Date(event.target.value));
  };

  const [selectedDateRange, setSelectedDateRange] = useState<string>("");
  const handleDateRangeChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const selectedRange = event.target.value as string;
    setSelectedDateRange(selectedRange);

    const now = new Date();
    let startDate: Date | null = null;
    let endDate: Date | null = null;

    switch (selectedRange) {
      case "custom":
        startDate = null;
        endDate = null;
        break;
      case "this_month":
        startDate = new Date(now.getFullYear(), now.getMonth(), 1);
        endDate = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        break;
      case "last_month":
        startDate = new Date(now.getFullYear(), now.getMonth() - 1, 1);
        endDate = new Date(now.getFullYear(), now.getMonth(), 0);
        break;
      case "last_3_months":
        startDate = new Date(now.getFullYear(), now.getMonth() - 3, 1);
        endDate = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        break;
      case "last_6_months":
        startDate = new Date(now.getFullYear(), now.getMonth() - 6, 1);
        endDate = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        break;
      case "last_12_months":
        startDate = new Date(now.getFullYear(), now.getMonth() - 12, 1);
        endDate = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        break;
      default:
        break;
    }

    setExportStartDate(startDate);
    setExportEndDate(endDate);
  };
  const [selectedChecklistTemplates, setSelectedChecklistTemplates] = useState<
    string[]
  >([]);
  const handleChecklistTemplateChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setSelectedChecklistTemplates(event.target.value as string[]);
  };
  const handleExportRequest = async (model: string) => {
    const formattedFilters: any = {};

    Object.keys(filters).forEach((key) => {
      formattedFilters[key] = filters[key];
    });

    switch (model) {
      case "checklists_report": {
        /*
          {
              "target_entity": "organization",
              "target_id": "{{organization}}",
              "export_entity": "checklists_report",
              "filters": "filters": "checklist_template=<>&due_date=$gt,<>,$lt,<>&inspection_program=$in,<>&building=$in,<>
          }
        */
        if (exportStartDate && exportEndDate) {
          formattedFilters[
            "inspection.due_date"
          ] = `$gt,${exportStartDate?.getTime()},$lt,${exportEndDate?.getTime()}`;
        }
        for (let i = 0; i < selectedChecklistTemplates.length; i++) {
          const checklist_template_id = selectedChecklistTemplates[i];
          // for (const checklist_template_id of checklists) {
          // queryStrings += `&checklist_template=${checklist_template_id}`;
          formattedFilters["checklist_template"] = checklist_template_id;
          const params = new URLSearchParams(formattedFilters).toString();
          const { data: response } = await createExportJob({
            args: [
              {
                myExportsCreateRequest: {
                  target_entity: "organization",
                  target_id: organization._id,
                  export_entity: "checklists_report",
                  filters: params,
                },
              },
            ],
          });
          setExportId(response?.data?.id);
        }
        break;
      }
      case "checklists": {
        const params = new URLSearchParams(formattedFilters).toString();
        const { data: response } = await createExportJob({
          args: [
            {
              myExportsCreateRequest: {
                target_entity: "organization",
                target_id: organization._id,
                export_entity: "checklists",
                filters: params,
                columns: Object.values(ExportColumns).filter(
                  (value) => value !== null && value !== ""
                ),
              },
            },
          ],
        });
        setExportId(response?.data?.id);
      }
    }
  };
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  useEffect(() => {
    const isValid =
      exportStartDate !== null &&
      exportEndDate !== null &&
      selectedDateRange !== "" &&
      selectedChecklistTemplates.length > 0;
    setIsFormValid(isValid);
  }, [
    exportStartDate,
    exportEndDate,
    selectedDateRange,
    selectedChecklistTemplates,
  ]);

  const [cancelExportId, setCancelExportId] = useState("");
  const { data: cancelJobResponse } = makeUseServiceCall(
    api.myExports.cancel,
    !!cancelExportId
  )({
    exportId: cancelExportId,
  });
  const handleCancelExport = async (exportId: string) => {
    if (exportId) {
      setCancelExportId(exportId);
    }
  };

  const {
    data: exportListReponse,
    isLoading: isProcessingExportList,
    mutate: refetchExports,
  } = makeUseServiceCall(api.myExports.list, open)();
  const exportList = useMemo(() => {
    if (exportListReponse) {
      return exportListReponse?.data.data || [];
    }
  }, [exportListReponse]);

  useEffect(() => {
    if (exportJobResponse) {
      const status = exportJobResponse?.data?.data?.status;
      if (status === "completed" || status === "errored") {
        setExportId("");
      }
      refetchExports();
    }
  }, [exportJobResponse, isProcessing, refetchExports]);
  useEffect(() => {
    if (cancelJobResponse) {
      setCancelExportId("");
      setExportId("");
      refetchExports();
    }
  }, [cancelJobResponse, refetchExports]);

  const [file_id, setFileId] = useState("");
  const { data: fileResponse } = makeUseServiceCall(
    api.files.fileGet,
    !!file_id
  )({
    organization: organization._id,
    file: file_id,
  });
  const [downloadFile, setDownloadFile] = useState(false);
  useEffect(() => {
    if (fileResponse?.data?.public_url && downloadFile) {
      const link = document.createElement("a");
      link.href = fileResponse.data.public_url;
      link.setAttribute("download", "");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setDownloadFile(false);
    }
  }, [fileResponse, downloadFile]);
  const handleDownload = (item: ExportResponse) => {
    setDownloadFile(true);
    setFileId(item.file_id);
  };

  const renderListItems = (list: ExportResponse[]) => {
    if (list.length === 0) {
      return (
        <ListItem>
          <ListItemIcon>
            <Info />
          </ListItemIcon>
          <ListItemText>Recent generated exports will show here.</ListItemText>
        </ListItem>
      );
    }

    return list
      .filter((item: ExportResponse) => item.status !== "canceled")
      .filter(
        (item) =>
          item.export_entity === "checklists" ||
          item.export_entity === "checklists_report"
      )
      .sort(
        (a, b) =>
          new Date(b.created_date).getTime() -
          new Date(a.created_date).getTime()
      )
      .map((item: ExportResponse) => {
        const exportDate = new Date(item.created_date);
        const formattedDate = `${exportDate.toLocaleDateString(
          "en-US"
        )} ${exportDate.toLocaleTimeString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        })}`;
        let file_name = item.file_key?.split("/").pop();
        if (item.status === "processing") {
          file_name = "Processing";
        } else if (item.status === "errored") {
          file_name = "Error generating export";
        }
        switch (item.export_entity) {
          case "checklists":
            file_name = "Checklists CSV";
            break;
          case "checklists_report":
            file_name = "Checklist Report CSV";
            break;
          default:
            break;
        }

        // Look for checklist_template in the filters query parameters, and get the id
        // Lookup the id in the checklist templates
        // Get the name of the checklist template
        const checklist_template_id = item?.filters?.match(
          /checklist_template=([^&]*)/
        );
        let checklist_template_name = "";
        if (
          checklistTemplates &&
          checklistTemplates.length > 0 &&
          checklist_template_id
        ) {
          checklist_template_name =
            checklistTemplates.find((t) => t._id === checklist_template_id[1])
              ?.name || "";
        }

        return (
          <ListItemHover key={item.id}>
            {item.status === "processing" ? (
              <IconButton onClick={() => handleCancelExport(item.id)}>
                <Cancel />
              </IconButton>
            ) : item.status === "errored" ? (
              <IconButton onClick={(event) => handleInfoClick(event, item)}>
                <ErrorOutline />
              </IconButton>
            ) : (
              <IconButton onClick={(event) => handleInfoClick(event, item)}>
                <Info />
              </IconButton>
            )}
            <ListItemText
              primary={
                <Box display="flex" alignItems="center">
                  <Typography variant="body2" sx={{ whiteSpace: "nowrap" }}>
                    {file_name}
                  </Typography>
                  {item.status === "processing" && (
                    <CircularProgress size={20} sx={{ marginLeft: 1 }} />
                  )}
                </Box>
              }
              secondary={
                <>
                  <Typography variant="caption">
                    {checklist_template_name && (
                      <>
                        {`Checklist: ${checklist_template_name}`}
                        <br />
                      </>
                    )}
                    {`Created: ${formattedDate}`}
                  </Typography>
                </>
              }
              onClick={() => handleDownload(item ?? "")}
              style={{
                cursor: item.status === "completed" ? "pointer" : "default",
                overflowY: "hidden",
              }}
            />
          </ListItemHover>
        );
      })
      .slice(0, 10);
  };
  const [anchorElInfo, setAnchorElInfo] = React.useState<null | HTMLElement>(
    null
  );
  const openInfo = Boolean(anchorElInfo);
  const idInfo = openInfo ? "simple-popper" : undefined;
  const [exportInfo, setExportInfo] = useState<ExportResponse | null>(null);
  const handleInfoClick = (
    event: React.MouseEvent<HTMLElement>,
    item: ExportResponse
  ) => {
    setAnchorElInfo(anchorElInfo ? null : event.currentTarget);
    setFileId(item.file_id);
    setExportInfo(item);
  };
  const formatFileSize = (size: number) => {
    if (size === 0) return "0 Bytes";
    const k = 1024;
    const dm = 2;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    const i = Math.floor(Math.log(size) / Math.log(k));
    return parseFloat((size / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  const formatFilters = (filters: string) => {
    if (!filters) return <li>All</li>;
    // Filters is a query string from the URL. We need to decipher it to display it in a human readable format
    // Convert string into a key value object
    const filterArray = filters.split("&");
    const filterObject: { [key: string]: string } = {};
    filterArray.forEach((filter) => {
      const [key, value] = filter.split("=");
      filterObject[key] = value;
    });
    const filterKeys = Object.keys(filterObject);
    if (filterKeys.length === 0) return <li>All</li>;
    return filterKeys.map((key) => {
      switch (key) {
        case "inspection_program": {
          const inspection_program_id = filterObject[key];
          const inspection_program = inspectionPrograms.find(
            (p) => p._id === inspection_program_id
          );
          return <li key={key}>IP Number: {inspection_program?.name}</li>;
        }
        case "checklist_template": {
          const checklist_template_id = filterObject[key];
          const template = checklistTemplates.find(
            (t) => t._id === checklist_template_id
          );
          return <li key={key}>Checklist: {template?.name || ""}</li>;
        }
        case "status": {
          const due_date_qs = filterObject[key];
          const decoded_qs = decodeURIComponent(due_date_qs);
          const status_ids = decoded_qs.split(",").slice(1);
          const status_names = status_ids.map((id) => {
            const status = CHECKLIST_STATUS_OPTIONS.find((s) => s.value === id);
            return status?.label || "";
          });
          return <li key={key}>Checklist Status: {status_names.join(", ")}</li>;
        }
        case "inspection.due_date":
        case "inspection.start_date": {
          const date_qs = filterObject[key];
          const decoded_qs = decodeURIComponent(date_qs);

          const date_split = decoded_qs.split(",");
          const date_operator = date_split[0] === "$gt" ? "After" : "Before";
          const date_value = new Date(parseInt(date_split[1]));
          return (
            <li key={key}>
              {`Due Date: ${date_operator} ${date_value.toLocaleDateString()}`}
            </li>
          );
        }
        case "building": {
          const building_qs = filterObject[key];
          const decoded_qs = decodeURIComponent(building_qs);
          const building_ids = decoded_qs.split(",").slice(1);
          const building_names = building_ids.map((id) => {
            const building = buildings.find((b) => b._id === id);
            return building?.name || "";
          });
          return <li key={key}>{`Building: ${building_names.join(", ")}`}</li>;
        }
        default: {
          const label = ExportColumns[key as keyof ExportColumnsType] || key;
          return <li key={key}>{`${label}: ${filterObject[key]}`}</li>;
        }
      }
    });
  };

  useEffect(() => {
    if (filters?.checklist_template) {
      setSelectedChecklistTemplates([filters.checklist_template]);
    }
    if (filters && filters["inspection.due_date"]) {
      const due_date = filters["inspection.due_date"];
      const due_date_split = due_date.split(",");
      const due_date_operator = due_date_split[0];
      const due_date_value = new Date(parseInt(due_date_split[1]));
      if (due_date_operator === "$gt") {
        setSelectedDateRange("custom");
        setExportStartDate(due_date_value);
      } else if (due_date_operator === "$lt") {
        setSelectedDateRange("custom");
        setExportEndDate(due_date_value);
      }
    }
  }, [filters]);

  return (
    <Drawer
      variant="permanent"
      anchor="right"
      open={open}
      onClose={onClose}
      sx={{
        position: "relative",
        "& .MuiDrawer-paper": {
          position: "relative",
          display: "flex",
          flexDirection: "column",
          height: "100%",
        },
        width: 400,
      }}
    >
      <Grid
        container
        spacing={2}
        columns={1}
        direction="column"
        sx={{
          width: 400,
          paddingLeft: 2,
          paddingRight: 2,
          height: "100%",
          overflowY: "hidden",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Grid item xs={12}>
          <Card
            variant="outlined"
            sx={{
              borderRadius: 1,
              transition: "0.3s cubic-bezier(.47,1.64,.41,.8)",
              "&:hover": {
                boxShadow: "0 4px 20px 0 rgba(0,0,0,0.12)",
                transform: "scale(1.02)",
              },
            }}
          >
            <CardHeader
              title={"Export Inspections Data"}
              avatar={
                <Avatar sx={{ bgcolor: "darkgrey" }} alt={"Exports"}>
                  <FileUploadOutlined />
                </Avatar>
              }
              action={<Button onClick={onClose}>Close</Button>}
            />
            <CardContent>
              <List>
                <ListItemButton
                  onClick={() => handleExportInfoDialogOpen()}
                  disabled={!!exportId}
                >
                  <ListItemIcon>
                    <Checklist />
                  </ListItemIcon>
                  Export Checklists Report
                </ListItemButton>
              </List>
              <List>
                <ListItemButton
                  onClick={() => handleExportRequest("checklists")}
                  disabled={!!exportId}
                >
                  <ListItemIcon>
                    <Checklist />
                  </ListItemIcon>
                  Export Checklists
                </ListItemButton>
              </List>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Card
            variant="outlined"
            sx={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              borderRadius: 1,
              transition: "0.3s cubic-bezier(.47,1.64,.41,.8)",
              "&:hover": {
                boxShadow: "0 4px 20px 0 rgba(0,0,0,0.12)",
                transform: "scale(1.02)",
              },
            }}
          >
            <CardHeader
              title={"Export List"}
              subheader={"10 most recent exports"}
            />
            <CardContent
              sx={{
                flexGrow: 1,
                overflowY: "auto",
              }}
            >
              <List sx={{ flexGrow: 1, overflowY: "auto" }}>
                {isProcessingExportList && (
                  <ListItem>
                    <ListItemIcon>
                      <CircularProgress size={20} />
                    </ListItemIcon>
                    <ListItemText>Processing...</ListItemText>
                  </ListItem>
                )}
                {exportList && renderListItems(exportList as ExportResponse[])}
              </List>
            </CardContent>
          </Card>
        </Grid>
        <Grid item></Grid>
      </Grid>
      <Popover
        id={idInfo}
        open={openInfo}
        anchorEl={anchorElInfo}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        onClose={() => setAnchorElInfo(null)}
      >
        <Card>
          <CardContent>
            <Typography variant="h6">File Information</Typography>
            {fileResponse ? (
              <>
                <Typography variant="body2">
                  File Name: {fileResponse.data.name}
                </Typography>
                <Typography variant="body2">
                  File Size:{" "}
                  {formatFileSize(fileResponse.data?.content_length || 0)}
                </Typography>
                <Typography variant="body2">
                  Created on:{" "}
                  {exportInfo && exportInfo.created_date
                    ? new Date(exportInfo.created_date).toLocaleString()
                    : ""}
                </Typography>
                <Box>
                  {exportInfo?.filters && (
                    <>
                      <Typography variant="body2">
                        Filtered: {exportInfo.filters ? "Yes" : "No"}
                      </Typography>
                      <Typography variant="body2">
                        Details: {formatFilters(exportInfo.filters)}
                      </Typography>
                    </>
                  )}
                  {/* Render other content here */}
                </Box>
              </>
            ) : (
              <Typography variant="body2">
                {exportInfo?.status === "errored"
                  ? "Unable to generate export at this time"
                  : "No file information available"}
              </Typography>
            )}
          </CardContent>
        </Card>
      </Popover>
      <Dialog
        open={exportInfoDialogOpen}
        onClose={handleExportInfoDialogClose}
        PaperProps={{
          component: "form",
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            handleExportRequest("checklists_report");
            handleExportInfoDialogClose();
          },
        }}
      >
        <DialogTitle>Export Inspections Checklists</DialogTitle>
        <DialogContent>
          <DialogContentText>Select Export Parameters</DialogContentText>
          <FormControl fullWidth margin="normal">
            <Grid container spacing={3} direction="column">
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="checklist-template-select-label">
                    Checklist Template
                  </InputLabel>
                  <Select
                    labelId="checklist-template-select-label"
                    id="checklist-template-select"
                    multiple
                    value={selectedChecklistTemplates}
                    onChange={
                      handleChecklistTemplateChange as (
                        event: SelectChangeEvent<string[]>,
                        child: React.ReactNode
                      ) => void
                    }
                    input={<OutlinedInput label="Checklist Template" />}
                    renderValue={(selected) => {
                      const selectedTemplates = (selected as string[]).map(
                        (id) => {
                          const template = checklistTemplates.find(
                            (t) => t._id === id
                          );
                          return template?.name || "";
                        }
                      );

                      const displayText = selectedTemplates.join(", ");
                      const isOverflowing = displayText.length > 30; // Adjust the length as needed

                      return isOverflowing
                        ? `${selectedTemplates.length} items selected`
                        : displayText;
                    }}
                    fullWidth
                    MenuProps={MenuProps}
                  >
                    {checklistTemplates.map((template) => (
                      <MenuItem key={template._id} value={template._id}>
                        <Checkbox
                          checked={selectedChecklistTemplates.includes(
                            template._id
                          )}
                        />
                        {template.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="date-range-select-label">
                    Due Date Range
                  </InputLabel>
                  <Select
                    labelId="date-range-select-label"
                    id="date-range-select"
                    value={selectedDateRange}
                    onChange={
                      handleDateRangeChange as (
                        event: SelectChangeEvent<string>,
                        child: React.ReactNode
                      ) => void
                    }
                    label="Date Range"
                  >
                    <MenuItem value="custom">Custom Date Range</MenuItem>
                    <MenuItem value="this_month">This Month</MenuItem>
                    <MenuItem value="last_month">Last Month</MenuItem>
                    <MenuItem value="last_3_months">Last 3 Months</MenuItem>
                    <MenuItem value="last_6_months">Last 6 Months</MenuItem>
                    <MenuItem value="last_12_months">Last 12 Months</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={5}>
                    <TextField
                      label="Start Date"
                      type="date"
                      value={
                        exportStartDate
                          ? exportStartDate.toISOString().split("T")[0]
                          : ""
                      }
                      onChange={handleExportStartDateChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={2} style={{ textAlign: "center" }}>
                    <Typography variant="body1">to</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      label="End Date"
                      type="date"
                      value={
                        exportEndDate
                          ? exportEndDate.toISOString().split("T")[0]
                          : ""
                      }
                      onChange={handleExportEndDateChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      margin="normal"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </FormControl>
          <DialogActions>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Button onClick={handleExportInfoReset}>Reset</Button>
              <Box>
                <Button onClick={handleExportInfoDialogClose}>Cancel</Button>
                <Button type="submit" disabled={!isFormValid}>
                  Export
                </Button>
              </Box>
            </Box>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </Drawer>
  );
};

export default ExportDrawer;
