import type { Dimension, Attribute, Filter } from "@sisense/sdk-data";

declare global {
  interface Window {
    Sisense?: {
      connect(url: string, persist?: boolean, token?: string): Promise<any>;
      internalScope: {
        prism: Prism;
        Highcharts: any;
      };
    };
    prism: any;
    Dashboard: any;
  }
}

// I need to extend the Filter type to include a string array of members
export interface InsightsFilter extends Filter {
  members: string[];
}

export type FilterOptions = {
  explicit: boolean;
  multiSelection: boolean;
  members: string[];
};

export type FilterDimension = Dimension & {
  FilterDim: Attribute;
};

export type DataSource = {
  fullname: string;
  id: string;
  address: string;
  database: string;
  live: boolean;
  title: string;
};

export type Prism = {
  user: {
    _id: string;
    email: string;
    userName: string;
    firstName: string;
    lastName: string;
    roleId: string;
    roleName: string;
  };
  license: {
    isTrial: boolean;
  };
  version: string;
};

// Resembles https://sisense.dev/reference/embedding/embed-sdk.html#appinfo
export type AppModel = {
  version: string;
  isTrial: boolean;
  user: UserInfo;
};

// Resembles https://sisense.dev/reference/embedding/embed-sdk.html#userinfo
export type UserInfo = {
  oid: string;
  email: string;
  username: string;
  firstName: string;
  lastName: string;
  role: {
    oid: string;
    name: string;
  };
};

// https://sisense.dev/reference/embedding/sisense.js/sisense-dash.html
export type DashboardModel = {
  _id: string;
  filters: Filter[];
  widgets: WidgetModelList;
  oid: string;
  title: string;
  description?: string;
  instanceType: string;
  owner: string;
  userId: string;
  datasource: DataSource;
  defaultFilters: Filter[];
  renderFilters: (container: HTMLDivElement) => void;
  refresh: () => void;
};

export type HighchartsChart = unknown;

export type WidgetModelList = {
  get: (widgetOId: WidgetModel["oid"]) => WidgetModel | undefined;
};

// https://sisense.dev/reference/js/dashboard/#eventscollection
export type SIENSE_DASHBOARD_EVENT =
  | "initialized"
  | "refreshstart"
  | "refreshend"
  | "widgetrefreshed"
  | "widgetadded"
  | "stylechanged"
  | "destroyed"
  | "editstart"
  | "editend"
  | "filterschanged"
  | "widgetinitialized"
  | "widgetbuildquery"
  | "widgetbeforequery"
  | "widgetprocessresult"
  | "widgetrender"
  | "widgetready"
  | "beforewidgetmenu"
  | "widgetdestroyed";

// https://sisense.dev/reference/js/widget/
export type SIENSE_WIDGET_EVENT =
  | "initialized"
  | "domready"
  | "refreshed"
  | "buildquery"
  | "beforequery"
  | "querystart"
  | "queryend"
  | "processresult"
  | "render"
  | "ready"
  | "readjust"
  | "beforewidgetindashboardmenu"
  | "beforewidgetmenu"
  | "beforeviewloaded"
  | "beforedatapointtooltip"
  | "destroyed"
  | "processcell";

export type SIENSE_WIDGET_CALLBACK_ARGS = {
  initialized: {
    widget: WidgetModel;
  };
  domready: {
    widget: WidgetModel;
  };
  refreshed: {
    widget: WidgetModel;
  };
  buildquery: {
    widget: WidgetModel;
    query: any;
  };
  beforequery: {
    widget: WidgetModel;
    query: any;
  };
  querystart: {
    widget: WidgetModel;
  };
  queryend: {
    widget: WidgetModel;
    query: any;
    rawResult: any;
    reason: boolean;
  };
  processresult: {
    widget: WidgetModel;
    query: any;
    result: any;
    rawResult: any;
    reason: boolean;
  };
  render: {
    widget: WidgetModel;
    reason: string;
    disabledAnimations: boolean;
  };
  ready: {
    widget: WidgetModel;
  };
  readjust: {
    widget: WidgetModel;
  };
  beforewidgetindashboardmenu: {
    widget: WidgetModel;
    items: any[];
    element: HTMLElement;
    cancel: boolean;
  };
  beforewidgetmenu: {
    widget: WidgetModel;
    items: any[];
    element: HTMLElement;
    cancel: boolean;
  };
  beforeviewloaded: {
    widget: WidgetModel;
    element: HTMLElement;
    options: any;
  };
  beforedatapointtooltip: {
    widget: WidgetModel;
    context: any;
    template: string;
    cancel: boolean;
  };
  destroyed: {
    widget: WidgetModel;
  };
  processcell: {
    widget: WidgetModel;
    panel: any;
    item: any;
    row: any;
    cell: any;
  };
};

// https://sisense.dev/reference/embedding/sisense.js/sisense-widget.html
export type WidgetModel = {
  oid: string;
  title: string;
  type: string;
  subtype: string;
  owner: string;
  userId: string;
  instanceType: string;
  datasource: DataSource;
  metadata: {
    panels: WidgetMetadataPanel[];
    ignore: object;
  };
  style: object;
  container: HTMLDivElement;
  on: (
    event: SIENSE_WIDGET_EVENT,
    callback: (
      event: any,
      args: SIENSE_WIDGET_CALLBACK_ARGS[SIENSE_WIDGET_EVENT]
    ) => void
  ) => void;
  refresh: () => void;
  redraw: () => void;
  resetDrill: () => void;
};

export type WidgetMetadataPanel = {
  items: WidgetMetadataPanelItem[];
  name: string;
  title: string;
  type: string;
};

export type WidgetMetadataPanelItem = {
  format: object;
  jaql: {
    dim: string;
    datatype: string;
    title: string;
    collapsed: boolean;
    filter: object;
    agg: string;
    merged: boolean;
    indexed: boolean;
    table: string;
    column: string;
    formula: string;
    context: object;
  };
  isCascading: boolean;
};

export interface InsightsTemplateSelection {
  title: string;
  widgetId: string;
  dashboardId: string;
  widgetType: string | undefined;
  height: number | undefined;
  width: number | undefined;
  filters: Filter[] | undefined;
  showIndicatorTitles: boolean;
}

export interface InsightsQueryData {
  widgetType: string;
  widgetTitle: string;
  widgetId: string;
  columns: any[];
  rows: any[];
}

export interface QueryWidgetSelection {
  widgetOid: string;
  widgetTitle: string;
  widgetType: string;
}

export enum InsightsWidgetType {
  CHART = "Chart",
  TABLE = "Table",
  INDICATOR = "Indicator",
}

export interface WidgetContainer {
  id: string;
  widgetId: string;
  widgetType: InsightsWidgetType | undefined;
  title: string;
}

export interface ImageData {
  url: string;
  widgetTitle: string;
}
